import _omit from 'lodash/omit';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
// import rgTheme from '../../styles/rgTheme';
import { Button } from '../Ant';
import Form from '../Form';
import { CTA } from '../Homepage';
import SearchBar from './SearchBar';
import { SearchArea, SearchBox, WidgetSection } from './styles';

const Widget = () => {
  const router = useRouter();

  const [values, setValues] = useState({
    name: '',
    id: '',
    order: 'Best Match',
    within_distance: '100',
    known_for: [],
    cost_list: [1, 8],
    skip_visited_destinations: false,
  });

  const onDestinationSelected = async destination => {
    setValues({
      ...values,
      id: destination.attributes.slug,
      name: destination.attributes.name,
    });
  };

  const handleSubmit = newValues => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const openBrowseDestinationPage = () => {
    const query = _omit(values, ['name']);
    if (!query.skip_visited_destinations) {
      delete query.skip_visited_destinations;
    }

    router.push({
      pathname: '/trip-planner',
      query,
    });
  };

  return (
    <SearchArea>
      <WidgetSection>
        <SearchBox>
          <h1
            style={{
              textAlign: 'center',
              color: 'black',
              margin: '0',
              fontSize: '24px',
            }}
          >
            Trip Planner
          </h1>
          <div
            style={{
              textAlign: 'center',
              marginBottom: '1em',
              color: 'rgb(75, 86, 99)',
              letterSpacing: '1px',
            }}
          >
            Find your perfect travel place
          </div>
          {/* <div style={{ color: '#9e9e9e', textAlign: 'left' }}>Find your perfect travel place</div> */}
          <Form name="destinationsSearch" initialValues={values} onSubmit={handleSubmit}>
            <SearchBar
              values={values}
              handleDestinationSelect={onDestinationSelected}
              update={true}
              homepage={true}
            />
          </Form>

          <CTA style={{ margin: '1em 0 0.5em' }}>
            <Button
              onClick={openBrowseDestinationPage}
              className="floating"
              size="large"
              color="primary"
            >
              🚘 Go!
            </Button>
          </CTA>
        </SearchBox>
      </WidgetSection>
    </SearchArea>
  );
};

export default Widget;
