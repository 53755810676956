import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Label, Value } from './index';

const ContentItem = ({ label, value, loose }) => {
  return (
    <Wrapper loose={loose}>
      <Label>{label}</Label> <Value>{value}</Value>
    </Wrapper>
  );
};

ContentItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  loose: PropTypes.bool,
};

export default ContentItem;
