import styled, { css } from 'styled-components';
import ContentItem from './ContentItem';

export { ContentItem };

export const Paragraph = styled.p`
  ${props => props.theme.font.readable};
`;

export const Wrapper = styled.span`
  margin-right: 1em;
  white-space: ${props => (props.loose ? 'initial' : 'nowrap')};
  display: inline-block;
`;

export const Label = styled.span`
  color: ${props => props.theme.color.grey75};
`;

export const Value = styled.span`
  color: grey;
`;

export const Highlight = styled.span`
  background-color: ${props => props.theme.color.lightyellow};
  border-radius: ${props => props.theme.borderRadius.sm};
  transition: background-color 0.2s ease;
  padding: 0 2px;
  &:hover {
    background-color: ${props => props.theme.color.darkYellow};
  }

  ${props =>
    props.paragraph &&
    css`
      background-color: white;
    `}
`;

export const UnstyledTag = styled.span`
  margin-right: 0.5em;
  margin-bottom: 0.25em;
  white-space: nowrap;
  display: flex;
  gap: 2px;
  svg {
    color: ${props => props.color || props.theme.color.grey20};
    font-size: 1.5em;
    vertical-align: text-bottom;
  }
`;

export const Meta = styled.span`
  color: ${props => props.theme.color.greyText};
`;
