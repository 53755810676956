import PropTypes from 'prop-types';
import React from 'react';
import { UnstyledTag } from '../Text';

const KnownForItem = ({ tag, index }) => {
  // const [check, setCheck] = useState(false);
  // Removed lazy load because it didnt look good when opening dropdown
  // useEffect(() => {
  //   if (userValue.length !== 0) {
  //     if (userValue.includes(tag.id)) {
  //       setCheck(true);
  //     } else {
  //       setCheck(false);
  //     }
  //   }
  // }, [tag, userValue]);

  return (
    <UnstyledTag key={`knownFor-${index}`}>
      {/* {check ? (
        <FaCheck style={{ color: 'green' }} />
      ) : ( */}
      <img
        width="21"
        style={{ height: '21px' }}
        src={`https://cdn.roadgoat.com/images/shared/icons/known-for/${tag.attributes.slug}-48.png`}
        alt={tag.attributes.name}
      />
      {/* )} */}
      {tag.attributes.name}
    </UnstyledTag>
  );
};

KnownForItem.propTypes = {
  index: PropTypes.number,
  tag: PropTypes.object,
  userValue: PropTypes.array,
};

export default KnownForItem;
