import { FaBookmark, FaThumbtack } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { Col } from '../Ant';
import { BoxContainer, ScrollWrap, Section } from '../Container';

export const WidgetSection = styled(Section)`
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
`;

export const FiltersHeader = styled.div`
  width: 100%;
  letter-spacing: 3px;
  color: ${props => props.theme.color.primary};
  margin: 0.5em 0.5em 0.5em 0;
  svg {
    margin-right: 0.5em;
    font-size: 1.25em;
    vertical-align: text-bottom;
  }
`;
export const HomepageFiltersHeader = styled(FiltersHeader)`
  letter-spacing: 1px;
  color: rgb(75, 86, 99);
`;

export const AutoCompleteHelp = styled.div`
  margin-bottom: 1em;
  margin-top: 3.5px;
  padding: 0 11px;
  font-size: 11px;
`;

export const Title = styled.div`
  font-size: 16px;
`;

export const MapForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0em 0 1em 0;
  padding: 0em 2em;
  .ant-slider {
    margin: 0 6px;
  }
  .ant-checkbox-inner {
    border-color: #ffb4d6;
  }
  .ant-form-item {
    margin: 0 7px 7px 0;
    border-radius: ${props => props.theme.borderRadius.md};
    padding: 0 4px;
    & :not(.no-shade) {
      background-color: ${props => props.theme.color.primaryLight};
    }
  }
  .no-shade {
    padding-left: 0;
  }
  .ant-form-item-control,
  label,
  .ant-form-item-label,
  .ant-select-arrow {
    color: ${props => props.theme.color.primary};
    font-size: 14px;
  }
  .ant-select-selection-search {
    min-width: 25px !important;
  }
  .distance .ant-select-selection-item,
  .sort-by .ant-select-selection-item {
    line-height: 32px !important;
  }

  &&& .ant-slider {
    @media only screen and (max-width: 575px) {
      margin-left: 14px;
    }
  }

  &&& .ant-form-item-label {
    @media only screen and (max-width: 575px) {
      flex: inherit;
      padding: initial;
    }
  }
  &&& .ant-form-item-control {
    @media only screen and (max-width: 575px) {
      flex: inherit;
    }
  }
`;

export const ResponsiveWrapper = styled.div`
  width: 100%;
  border-radius: ${props => props.theme.borderRadius.md};
  background-size: cover;
  border: 5px solid white;
  box-sizing: content-box;
  margin: -5px 0 0 -5px;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:hover {
    box-shadow: 0 0 0px 3px ${props => props.theme.color.secondary};
  }
`;

export const ResponsiveInner = styled.div`
  padding-top: 66%;
`;

export const Name = styled.a`
  color: ${props => props.theme.color.secondary};
  &:hover {
    color: ${props => props.theme.color.secondary};
  }
`;

export const CheckInBtn = styled(FaThumbtack)`
  color: grey;
  margin-right: 0.5em;
  cursor: pointer;
`;

export const BookmarkBtn = styled(FaBookmark)`
  color: grey;
  cursor: pointer;
`;

export const CheckInBtnSP = styled(FaThumbtack)`
  color: white;
  margin-right: 0.5em;
  cursor: pointer;
`;

export const BookmarkBtnSP = styled(FaBookmark)`
  color: white;
  cursor: pointer;
`;

export const ResultCol = styled(Col)`
  text-align: center;
  margin-bottom: 1em;
  .name {
    color: ${props => props.theme.color.secondary};
    &:hover {
      color: ${props => props.theme.color.secondary};
    }
  }
`;

export const SearchedDestinationImg = styled.img`
  border-top-left-radius: ${props => props.theme.borderRadius.md};
  border-bottom-left-radius: ${props => props.theme.borderRadius.md};
  height: 100%;
  width: 80px;
  object-fit: cover;
`;

export const SkeletonImage = styled(BoxContainer)`
  height: 130px;
`;

export const CharWrap = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0.5em;
`;

const floating = keyframes`
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(8%);
	}
	100% {
		transform: translateY(0%);
	}
  `;

export const SearchArea = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(80vh - 55px);
  background: url('/images/pages/homepage/backdrop.webp') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-select-selection-search {
    min-height: 38px !important;
  }
  .filtersheader {
    margin-bottom: 0;
  }
  .ant-select-dropdown {
    background-color: #eadae1;
  }
  .ant-form-item {
    margin: 0 0 -0.5em 0;
  }
  .ant-select-arrow {
    font-size: 1.25em;
    top: 14px;
  }
  svg {
    color: ${props => props.theme.color.primary};
  }
  .ant-select-selection-search {
    min-width: 25px !important;
  }
  .ant-form-item-label > label {
    height: 38px;
    font-size: 16px;
  }
  input,
  .ant-select-selection-placeholder {
    font-size: 16px;
  }
  .ant-slider {
    margin-top: 14px;
  }
  .ant-select-selector {
    cursor: pointer;
  }
  .floating {
    animation-name: ${floating};
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }
  .no-shade {
    display: none;
  }
  .ant-select-autocomplete {
    margin-bottom: 0.5em;
  }
  .ant-form-item-control-input {
    min-height: 38px;
  }
  &&& {
    .ant-select-selector {
      background-color: white;
      border: none;
    }
    .searchbar {
      margin-bottom: 1em;
      .ant-select-selection-placeholder {
        color: #515151;
      }
    }
    @media (max-width: 575px) {
      .ant-form .ant-form-item .ant-form-item-label {
        flex: initial;
      }
      .ant-form .ant-form-item .ant-form-item-control {
        flex: 1 1 0;
      }
      .ant-slider {
        margin-left: 14px;
      }
    }
  }
  @media (max-width: 575px) {
    height: calc(100vh - 60px);
  }
`;

export const SearchBox = styled.div`
  width: 350px;
  max-width: 100%;
  padding: 0.5em 1em;
  @media (max-width: 400px) {
    width: 300px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
  }
  .searchbar {
    .ant-select-selection-placeholder {
      color: #515151 !important;
    }
  }
  .ant-select-selection-placeholder {
    line-height: 40px !important;
  }

  .ant-select-selection-search-input {
    height: 40px !important;
  }
`;

export const SearchedDestinationBox = styled(BoxContainer)`
  width: 100%;
  display: flex;
  padding: 0;
  color: white;
  margin: 0.5em 0;
  overflow: hidden;
  background-color: ${props => props.theme.color.secondary};

  .place {
    flex-grow: 1;
  }
  .place-info {
    background-color: ${props => props.theme.color.tertiary};
    display: flex;
    padding-left: 1em;
  }
  .travel-guide-link {
    text-align: right;
    margin: 3.5px 7px;
    color: white;
  }
`;

export const BDScrollWrap = styled(ScrollWrap)`
  padding: 0;
`;
