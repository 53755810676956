/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from 'formik';
import { Checkbox, Slider } from 'formik-antd';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaSearchLocation } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import keyWindows from '../../constants/keyWindows';
import { Store } from '../../store';
import difference from '../../utils/difference';
import { Button, Form, Formik } from '../Ant';
import BorderlessInput from '../Ant/Input/BorderlessInput';
import BaseAutoComplete from '../AutoComplete';
import KnownForItem from '../KnownFor/Item';
import { FiltersHeader, HomepageFiltersHeader } from './styles';

const { Select, Input } = Formik;
const { Option } = Select;

const KEY_WINDOW = keyWindows.BROWSE_DESTINATIONS_FILTERS;
const IGNORE_FIELD_CHANGES = ['name'];

const SearchBar = props => {
  const [fieldChanges, setFieldChanges] = useState([]);

  const dispatch = useDispatch();
  const formContext = useFormikContext();
  const { values } = formContext;
  const { isAuthenticated } = useSelector(Store.auth.selectors.get);

  const knownFor = useSelector(Store.knownFor.selectors.getKnownforsByKeyWindow(KEY_WINDOW));

  const handleSubmit = async () => {
    await formContext.setFieldValue('page', 1);
    return formContext.submitForm();
  };

  useEffect(() => {
    dispatch(Store.knownFor.actions.fetchKnownFor(KEY_WINDOW));
  }, []);

  useEffect(() => {
    const fieldsToUpdate = difference(values, props.values);
    setFieldChanges(fieldsToUpdate.filter(field => !IGNORE_FIELD_CHANGES.includes(field)));
    if (fieldsToUpdate.length) {
      fieldsToUpdate.forEach(field => {
        formContext.setFieldValue(field, props.values[field]);
      });
    }
  }, [props.values]);

  useEffect(() => {
    const fieldsToUpdate = difference(values, props.values);
    setFieldChanges(fieldsToUpdate.filter(field => !IGNORE_FIELD_CHANGES.includes(field)));
    if (
      fieldsToUpdate.length > 0 &&
      (fieldsToUpdate[0] !== 'name' || fieldsToUpdate.length > 1) &&
      props.update
    ) {
      handleSubmit();
    }
  }, [values]);

  const budgetTooltip = {
    1: 'Very Low',
    2: 'Low',
    3: 'Medium Low',
    4: 'Medium',
    5: 'Medium High',
    6: 'High',
    7: 'Very High',
    8: 'Extermely High',
  };

  const formatter = value => {
    return budgetTooltip[value];
  };

  const destinationChanged = async (option = {}) => {
    return props.handleDestinationSelect(option.destination, formContext);
  };

  return (
    <>
      <Input type="hidden" name="page" />
      <Input type="hidden" name="search_map" />

      <BaseAutoComplete
        formik={true}
        name="name"
        value={values.name}
        type="small"
        style={{ width: '100%' }}
        handleSelect={(_value, option) => destinationChanged(option)}
        allowClear={false}
        placeholder={props.homepage && 'Within or near...'}
        size={props.homepage && 'large'}
        className="searchbar"
      >
        {!props.homepage ? (
          <BorderlessInput
            prefix={<FaSearchLocation style={{ transform: 'scaleX(-1)' }} />}
            name="name"
            placeholder="Where to, master?"
          />
        ) : null}
      </BaseAutoComplete>
      <Input name="id" type="hidden" />
      <Input name="destination_type" type="hidden" />
      {props.homepage ? (
        <HomepageFiltersHeader className="filtersheader">
          <img style={{ height: '18px' }} src="images/shared/icons/dials.png" alt="Filters" />
          &nbsp;Filters
        </HomepageFiltersHeader>
      ) : (
        <FiltersHeader className="filtersheader">
          <img style={{ height: '18px' }} src="images/shared/icons/dials.png" alt="Filters" />
          &nbsp;FILTERS
        </FiltersHeader>
      )}

      <Form.Item label="Sort By">
        <Select
          suffixIcon={<FaChevronDown />}
          name="order"
          bordered={false}
          dropdownMatchSelectWidth={120}
          size={props.homepage && 'large'}
          className="sort-by"
        >
          <Option value="Best Match">Best Match</Option>
          <Option value="Most Visited">Most Popular</Option>
          {/* Temporarily blocking Highest Rated due to BE mods needed */}
          {/* <Option value="Highest Rated">Highest Rated</Option> */}
        </Select>
      </Form.Item>

      {values.destination_type === 'City' && (
        <Form.Item label="Distance">
          <Select
            suffixIcon={<FaChevronDown />}
            name="within_distance"
            bordered={false}
            dropdownMatchSelectWidth={120}
            size={props.homepage && 'large'}
            className="distance"
          >
            <Option value="25">25 Miles</Option>
            <Option value="50">50 Miles</Option>
            <Option value="100">100 Miles</Option>
            <Option value="200">200 Miles</Option>
            <Option value="400">400 Miles</Option>
          </Select>
        </Form.Item>
      )}

      <Form.Item label="Known For">
        <Select
          style={{ width: '100%' }}
          name="known_for"
          bordered={false}
          dropdownMatchSelectWidth={150}
          mode="multiple"
          showArrow={true}
          suffixIcon={<FaChevronDown />}
          placeholder={props.homepage && 'Choose'}
          size={props.homepage && 'large'}
          className="knownfor"
        >
          {knownFor.data.map((knownForItem, index) => (
            <Option key={index} value={knownForItem.attributes.slug}>
              <KnownForItem tag={knownForItem} index={index} />
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={props.homepage ? 'Your Budget' : 'Budget'}>
        <Slider
          name="cost_list"
          tipFormatter={formatter}
          min={1}
          max={8}
          range
          style={!props.homepage ? { width: '100px' } : null}
          size={props.homepage && 'large'}
        />
      </Form.Item>
      {isAuthenticated && (
        <Form.Item label="Ignore destinations I've Been" style={{ height: '32px' }}>
          <Checkbox name="skip_visited_destinations"></Checkbox>
        </Form.Item>
      )}
      {!_isEmpty(fieldChanges) && !props.update && (
        <Form.Item className="no-shade">
          <Button
            color="primary"
            boxshadow="true"
            onClick={handleSubmit}
            style={{ borderRadius: '4px', height: '32px', lineHeight: '20px' }}
          >
            Apply Filters
          </Button>
        </Form.Item>
      )}
    </>
  );
};

SearchBar.propTypes = {
  handleDestinationSelect: PropTypes.func,
  values: PropTypes.object,
  update: PropTypes.bool,
  homepage: PropTypes.bool,
  destinations: PropTypes.object,
  currentPath: PropTypes.string,
  selectedDestinationId: PropTypes.string,
  getDestinationPosition: PropTypes.func,
  onChangePage: PropTypes.func,
  onClearSelectedDestination: PropTypes.func,
};

export default SearchBar;
